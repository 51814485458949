import Vue from 'vue';
import moment from 'moment';

moment.locale("en-gb");

Object.defineProperties(Vue.prototype, {
    $moment: {
        get() {
            return moment;
        }
    }
});

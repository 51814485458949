<template>
    <v-app>

        <v-main>
            <v-container 
                fill-height 
                fluid
                id="not-logged-in"
                >
                <v-spacer />
                <v-row>

                    <v-container>
                        <v-row justify="center">
                            
                            <img
                                id="brand-logo"
                                src="@/assets/logo.png"
                            />
                        </v-row>
                        <h3 class="mt-2 text-center" id="subtitle">
                            Claims Portal
                        </h3>
                        <div class="mt-8"></div>
                        <v-row>
                            <v-col class="mx-3 text-center">
                                <v-btn
                                    id="login-button"
                                    depressed
                                    color="primary"
                                    :block="$vuetify.breakpoint.xs"
                                    rounded
                                    @click="reallyRedirectToLogin()"
                                    min-width="250"
                                    :loading="loading"                                    
                                >
                                    Log in
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>

                </v-row>
            </v-container>
        </v-main>
                
        <v-footer
            padless
            color="rgb(0, 0, 0, 0)"
            >
            <v-col
                class="text-center grey--text text--lighten-1"
                cols="12"
                >
                {{version}}
            </v-col>
        </v-footer>

    </v-app>
</template>

<script>
import { SharedoAuth, InstallPrompt } from '@sharedo/mobile-core'

export default {
    name: "NotLoggedIn",

    data: function () {
        return {
            loading: false,
            version: null,
        };
    },

    mounted: function() {
        InstallPrompt.init();

        this.version = "v" + process.env.VUE_APP_VERSION;
    },

    methods: {
        reallyRedirectToLogin() {
            this.loading = true;

            SharedoAuth.reallyRedirectToLogin();
        },
    },
};
</script>

<style scoped>
    @keyframes slideup {
        from {
            opacity: 0.5;
            transform: translate(0, 30px);
        }

        to {
            opacity: 1;
            transform: translate(0, 0);
        }
    }
    
    @keyframes fadein {
        0% {opacity: 0;}
        50% {opacity: 0;}
        100% { opacity: 1;}
    }

    #brand-logo {
        width: 250px;
        animation-duration: 800ms;
        animation-name: slideup;
    }

    #login-button {
        animation-duration: 1400ms;
        animation-name: fadein;
    }

    #subtitle {
        color: #e10a0a;
        animation-duration: 800ms;
        animation-name: fadein;
    }
        
    @media (min-width: 600px) {
        #brand-logo {
            width: 400px;
        }    
    }
</style>

<style>
    #not-logged-in .v-banner {
        position: fixed !important;
        top: 12px;
        right: 12px;
        left: 12px;
    }
</style>
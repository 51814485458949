import { SharedoFetch, SharedoProfile } from '@sharedo/mobile-core'

var appChannelType = "push";

/* Just returns the APP notification settings, format:
 *
 *   categories: [
 *       {
 *           name: "Approvals"
 *           settings: [
 *               {
 *                   name: "Approval Approved",
 *                   active: true
 *               },
 *               ...
 *           ],
 *       }
 *   ]
 */
function getSettings()
{
    // TODO Public API
    return SharedoFetch.get(`/api/user/notifications/${SharedoProfile.profile.userId}/settings`).then(data => {
        
        // We just want APP notification settings
        var categories = [];

        data.categories.forEach(cat => {
        
            var settings = [];

            cat.notificationTypes.forEach(nt => {
        
                // Check if "App - Never notify" channel is selected, otherwise assume default ("App - In application notifications")
                var cp = nt.channelPreferences.find(nt => nt.channelTypeSystemName === appChannelType && nt.frequency === 5);
                if (!cp) return;

                settings.push({
                    name: nt.name,
                    active: cp.selected,
                    systemName: nt.systemName,
                });
            });

            categories.push({
                name: cat.categoryOptionSetValue.name,
                settings: settings,
            })
        });
        return categories;
    });
}

function saveSettings(categories)
{
    var settingList = [];

    categories.forEach(cat => {
        cat.settings.forEach(setting => {
            settingList.push({
                channelTypeSystemName: appChannelType,
                frequency: setting.active ? 5 : 0,
                notificationTypeSystemName: setting.systemName,
            });
        });
    });

    // TODO Public API
    return SharedoFetch.post(`/api/user/notifications/${SharedoProfile.profile.userId}/settingsForChannelType/${appChannelType}`, settingList);
}

function getWebPushConfig()
{
    // Call fails if feature disabled
    return SharedoFetch.get(`/api/v1/public/notifications/webPushConfig`);
}

// config - { endpoint: ..., p256dh: ..., auth: ... }
function registerForWebPush(config)
{
    return SharedoFetch.post(`/api/v1/public/notifications/registerForWebPush`, config);
}

function get(userId) {
    return SharedoFetch.get(`/api/notifications/${userId}/quickView`);
}

function dismiss(userId, notificationId) {
    return SharedoFetch.delete(`/api/notifications/${notificationId}/${userId}/dismiss`);
}

function dismissAll(userId) {
    return SharedoFetch.delete(`/api/notifications/${userId}/dismiss`);
}

export default 
{
    get,
    dismiss,
    dismissAll,
    getSettings,
    saveSettings,
    getWebPushConfig,
    registerForWebPush,
};

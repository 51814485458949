<template>
    <v-app>
        <v-main class="has-bottom-nav">
            <router-view></router-view>

            <VBottomNav>
                <template slot="contents">
                    <v-btn :to="{ path: '/tasks' }" v-if="!isFeeEarner()">
                        <span>Jobs</span>
                        <v-icon
                            >mdi-checkbox-multiple-marked-circle-outline</v-icon
                        >
                    </v-btn>

                    <v-btn :to="{ path: '/tasks' }" v-if="isFeeEarner()">
                        <span>Tasks</span>
                        <v-icon
                            >mdi-checkbox-multiple-marked-circle-outline</v-icon
                        >
                    </v-btn>

                    <v-btn :to="{ path: '/notifications' }">
                        <span>Notifications</span>
                        <v-badge
                            :value="unreadNotifications > 0"
                            :content="unreadNotifications"
                            color="error lighten-1"
                            offset-x="12"
                            offset-y="14"
                        >
                            <v-icon>mdi-bell-outline</v-icon>
                        </v-badge>
                    </v-btn>

                    <v-btn :to="{ name: 'search' }">
                        <span>Search</span>
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>

                    <v-btn :to="{ name: 'profile' }">
                        <span>Profile</span>
                        <v-icon>mdi-account-outline</v-icon>
                    </v-btn>
                </template>
            </VBottomNav>
        </v-main>
    </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { SharedoProfile } from "@sharedo/mobile-core";
import serviceWorkerBridge from "@/mixins/serviceWorkerBridge";
import notifications from "@/views/Notifications/notificationAgent";

export default {
    name: "Main",

    mixins: [serviceWorkerBridge],

    data: function () {
        return {
            persona: null,
        };
    },

    computed: {
        ...mapState({
            unreadNotifications: (state) => state.notifications.unread,
        }),
    },

    mounted: async function () {
        this.persona = SharedoProfile.profile.persona;

        // Set body colour for iOS navbar
        document.getElementsByTagName("body")[0].style.background =
            "var(--v-primary-base)";

        await this.getNotificationCount();
    },

    methods: {
        ...mapActions({
            setUnreadNotifications: "setUnreadNotifications",
        }),
        async getNotificationCount() {
            try {
                const notificationsResponse = await notifications.get(
                    SharedoProfile.profile.userId
                );

                this.setUnreadNotifications(
                    notificationsResponse.numberOfNewItems
                );
            } catch (error) {
                console.error(error);
            }
        },
        isFeeEarner() {
            return this.persona === "fee-earner";
        },
    },
};
</script>
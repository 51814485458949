import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    {
        // Tab 1
        path: '/tasks',
        alias: '/',           // <= default page
        name: 'tasks',
        component: () => import('../views/Tasks/TaskList.vue')
    },
    {
        // Tab 1 -> Detail
        path: "/tasks/:id",
        name: "task-detail",
        component: () => import('../views/Tasks/TaskDetail.vue')
    },
    {
        // Tab 1 -> Detail -> Comments
        path: "/tasks/:sharedoId/comments",
        alias: "/instructions/:sharedoId/comments",
        name: "task-comments",
        component: () => import('../views/Comments/CommentList.vue')
    },
    {
        // Tab 1 -> Detail -> Chronology
        path: "/tasks/:sharedoId/chronology",
        alias: "/instructions/:sharedoId/chronology",
        name: "task-chronology",
        component: () => import('../views/Chronology/ChronologyList.vue')
    },
    {
        // Tab 1 -> Detail -> Time Entries
        path: "/tasks/:sharedoId/time-entries",
        alias: "/instructions/:sharedoId/time-entries",
        name: "task-time-entries",
        component: () => import('../views/TimeEntries/TimeEntryList.vue')
    },
    {
        // Tab 1 -> Detail -> Participants
        path: "/tasks/:sharedoId/participants",
        alias: "/instructions/:sharedoId/participants",
        name: "task-participants",
        component: () => import('../views/Participants/ParticipantList.vue')
    },
    {
        // Tab 1 (jobs)
        path: '/cpd-instructions',
        name: 'cpdInstructions',
        component: () => import('../views/ClaimantPropertyDamageInstructions/InstructionList.vue')
    },
    {
        // Tab 1 -> Detail (jobs)
        path: "/instructions/:id",
        name: "instruction-detail",
        component: () => import('../views/ClaimantPropertyDamageInstructions/InstructionDetail.vue')
    },
    {
        // Tab 1 -> Detail (payment)
        path: "/payments/:id",
        name: "payment-detail",
        props: true,
        component: () => import('../views/Payments/PaymentDetail.vue')
    },
    {
        // Tab 1 -> Bookmarks
        path: "/bookmarks",
        name: "bookmarks",
        component: () => import('../views/Bookmarks/BookmarkList.vue')
    },
    {
        // Tab 2
        path: '/notifications',
        name: 'notifications',
        component: () => import('../views/Notifications/NotificationList.vue')
    },
    {
        // Tab 2 -> Settings
        path: "/notifications/settings",
        name: "notification-settings",
        component: () => import('../views/Notifications/NotificationSettings.vue')
    },
    {
        // Tab 3
        path: '/search',
        name: 'search',
        component: () => import('../views/Search/Search.vue')
    },
    {
        // Tab 4
        path: '/profile',
        name: 'profile',
        component: () => import('../views/Profile/Profile.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes,
});

export default router;

import Vue from 'vue';
import vuetify from './vuetify'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'

// Rich text editor
Vue.use(TiptapVuetifyPlugin, {
    vuetify,
    iconsGroup: "mdi",
});
